import { NgModule } from '@angular/core';
import { SharedModule } from '../../Shared/shared.module';
import {RegelLoeshenComponent} from "./regel-loeshen.component";
import {RegelloeshenRoutingModule} from "./regelloeshen-routing.module";

@NgModule({
  declarations: [
    RegelLoeshenComponent,
  ],
  imports: [
    RegelloeshenRoutingModule,
    SharedModule,
  ]
})
export class RegelLoeshenModule { }
