


  <mat-card class="create-request-card">

    <mat-card-title>Create a New Change Request</mat-card-title>
    <mat-card-header>BWI Rule Removal Request</mat-card-header>
    <br>
    <mat-divider></mat-divider>
    <mat-card-content>
      <form [formGroup]="ruleDeleteForm">

        <div class="form-header">
          <div class="form-buttons">
            <button mat-raised-button color="primary" type="submit" (click)="onSaveDraft()">Save Draft</button>
            <button mat-raised-button color="primary" type="button" (click)="nextStep()">Next</button>
          </div>
        </div>

        <p>Create a New Change Request </p>

        <div>
          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Owner</mat-label>
            <mat-select required formControlName="owner">
              <mat-option value="Owner1">Owner1</mat-option>
              <mat-option value="Owner2">Owner2</mat-option>
            </mat-select>
            <mat-error *ngIf="ruleDeleteForm.get('owner')?.hasError('required')">
              Owner is required
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Subject</mat-label>
            <input required matInput placeholder="Enter subject" formControlName="subject">
            <mat-error *ngIf="ruleDeleteForm.get('subject')?.hasError('required')">
              Subject is required
            </mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Due</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="due">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-error *ngIf="ruleDeleteForm.get('due')?.hasError('required')">
              Due date is required
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Describe the Issue</mat-label>
          <textarea matInput formControlName="issueDescription" ></textarea>
          <mat-error *ngIf="ruleDeleteForm.get('issueDescription')?.hasError('required')">
            Issue description is required
          </mat-error>
        </mat-form-field>


        <div class="form-group" appearance="outline">
          <label class="attachments-label">Attachments</label>
          <input type="file" multiple (change)="onFileSelected($event)" #fileInput>
          <button type="button" mat-raised-button color="primary" (click)="fileInput.click()">
            <mat-icon>attach_file</mat-icon>
            Add more files
          </button>
          <ul>
            @for (item of files; track $index) {
            <li>{{ item.name }}</li>
            }
          </ul>
        </div>




        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Expires</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="expires">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="ruleDeleteForm.get('expires')?.hasError('required')">
            Expiry date is required
          </mat-error>
        </mat-form-field>

        <div class="form-row">
          <mat-form-field class="input-field" appearance="outline">
            <mat-label>Requestor</mat-label>
            <input matInput required placeholder="Enter requestor" value="" formControlName="requestor" >
            <mat-error *ngIf="ruleDeleteForm.get('requestor')?.hasError('required')">
              Requestor is required
            </mat-error>
          </mat-form-field>
        </div>


        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Device Name</mat-label>
          <mat-select required formControlName="deviceName">
            <mat-option value="device1">Device 1</mat-option>
            <mat-option value="device2">Device 2</mat-option>
            <mat-option value="device3">Device 3</mat-option>
          </mat-select>
          <mat-error *ngIf="ruleDeleteForm.get('deviceName')?.hasError('required')">
            Device Name is required
          </mat-error>
        </mat-form-field>

      </form>
    </mat-card-content>
  </mat-card>


<div class="form-container">
  <mat-label>Rule to remove:</mat-label>
  <mat-card>
    <mat-card-content>
      <div>
        <button mat-raised-button color="primary">Select Rules</button>
      </div>
      <mat-label>Requested Action:</mat-label>
      <mat-radio-group aria-label="Select an action">
        <mat-radio-button value="disable">Disable rule</mat-radio-button>
        <mat-radio-button value="remove">Remove rule</mat-radio-button>
      </mat-radio-group>
    </mat-card-content>
  </mat-card>

  <mat-label>External change request id</mat-label>
  <mat-form-field appearance="outline" class="input-field">
    <input matInput placeholder="">
  </mat-form-field>

  <div class="workflow-info">
    <p>Workflow: BWI-Rule-Removal-Parallel-Approval-Workflow</p>
    <p>From Template: BWI Rule Removal Request</p>
  </div>

  <div class="form-header">
    <button mat-raised-button color="primary" class="back-button" type="button" (click)="prevStep()">Back</button>
    <div class="form-buttons">
      <button mat-raised-button color="primary" type="submit">Save Draft</button>
      <button mat-raised-button color="primary" type="button">Next</button>
    </div>
  </div>
</div>

