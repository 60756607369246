import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../../alert.service";

@Component({
  selector: 'app-regel-loeshen',
  templateUrl: './regel-loeshen.component.html',
  styleUrl: './regel-loeshen.component.scss'
})
export class RegelLoeshenComponent {
  currentStep = 1;
  files: File[] = [];
  ruleDeleteForm: FormGroup;

  constructor(private fb: FormBuilder, private alertService: AlertService) {
    this.ruleDeleteForm = this.fb.group({
      owner: ['', Validators.required],
      subject: ['', Validators.required],
      due: ['', Validators.required],
      issueDescription: ['', Validators.required],
      attachments: [''],
      expires: ['', Validators.required],
      requestor: ['', Validators.required],
      deviceName: ['', Validators.required]
    });
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      for (let i = 0; i < input.files.length; i++) {
        this.files.push(input.files[i]);
      }

    }
  }
  nextStep() {
    if (this.currentStep == 1){
      this.currentStep++
    }
  }

  prevStep() {
    if (this.currentStep == 2){
      this.currentStep--
    }
  }

  onSaveDraft(){
    if (this.ruleDeleteForm.valid) {
      console.log(this.ruleDeleteForm.value);
      this.alertService.showAlert('Daten erfolgreich gespeichert!', 'success');
    } else {
      console.error('Form is invalid', this.ruleDeleteForm.errors);
      this.alertService.showAlert('Beim Speichern der Daten ist ein Fehler aufgetreten.', 'error');
    }
  }
}
